import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Fade } from 'react-awesome-reveal'

import { GlobalDispatchContext } from 'context/GlobalContextProvider'
import { SitesContext } from 'context/SitesContextProvider'
import selectSite from 'actions/sites/select'

import Layout from 'components/Layout'
import WordpressSEO from 'components/WordpressSEO'
import Logo from 'components/Logo'
import CloudinaryMedia from 'components/CloudinaryMedia'
import SplashText from 'components/SplashText'

import s from './index.module.css'
import getCurrentSiteBusinessUnit from '../helpers/getCurrentSiteBusinessUnit'

function Index({ globalSettings, refreshOnChange }) {
  const router = useRouter()
  const dispatch = useContext(GlobalDispatchContext)
  const [activeLocation, setActiveLocation] = useState('')
  const [image1, setImage1] = useState(null)
  const [image2, setImage2] = useState(null)
  const [imageSwap, setImageSwap] = useState(false)
  const [imagesVisible, setImagesVisible] = useState(false)
  const { sites } = useContext(SitesContext)

  const isSingleSite = typeof sites !== 'undefined' && sites.length === 1

  useEffect(() => {
    if (!isSingleSite) {
      return
    }

    const site = sites[0]
    const { name, path } = site
    router.push(
      {
        pathname: '/[site]',
        query: { locationsSplash: false },
      },
      path.replace(/\/$/, '')
    )
  }, [])

  const halfSitesArr = Math.ceil(sites.length / 2)
  const firstSitesArr = isSingleSite ? [] : sites.slice(0, halfSitesArr)
  const secondSitesArr = isSingleSite ? [] : sites.slice(-halfSitesArr)

  const handleSelectSite = ({ id, path, name }) => {
    dispatch(selectSite(id))
    if (refreshOnChange) router.reload()
    else {
      setActiveLocation(name)
      router.push(
        {
          pathname: '/[site]',
          query: { locationsSplash: true },
        },
        path.replace(/\/$/, '')
      )
    }
  }

  const handleMouseOver = (img1, img2) => {
    setImagesVisible(true)
    setImage1(img1)
    setImage2(img2)
  }

  const setLocations = (locations = [], region) => {
    if (locations.length < 1) return null
    return (
      <div>
        {region && <h2 className={s.region}>{region}</h2>}
        <div className={s.locations}>
          {locations.map((location, i) => (
            <button
              key={location.id}
              type="button"
              onClick={() => handleSelectSite(location)}
              onMouseOver={() =>
                handleMouseOver(
                  location.image1,
                  location.image2,
                  setImageSwap(i % 2)
                )
              }
              onMouseOut={() => setImagesVisible(false)}
              className={s.location}
            >
              {location.name}
            </button>
          ))}
        </div>
      </div>
    )
  }

  const setImg = (img, classname = '', classnameAlternate = '') => {
    if (!img) return null
    return (
      <CloudinaryMedia
        className={classNames(classname, classnameAlternate, {
          [s.imgVisible]: imagesVisible,
        })}
        images={[{ url: img, params: 'e_grayscale,f_auto' }]}
      />
    )
  }

  return (
    <Layout globalSettings={globalSettings} footerLess>
      <WordpressSEO
        title="Choose Location | Select Model Management"
        desc="Select Model Management"
      />
      {setImg(image1, s.img1, imageSwap ? s.img1Top : '')}
      {setImg(image2, s.img2, imageSwap ? s.img2Bottom : '')}
      <div className={s.container}>
        <div className={s.logoWrapper}>
          <Fade duration={500} triggerOnce>
            <Logo
              className={s.logo}
              colour="#fff"
              businessUnit={getCurrentSiteBusinessUnit()}
            />
          </Fade>
        </div>
        <Fade duration={500} delay={700} triggerOnce>
          {setLocations(firstSitesArr, 'Europe')}
        </Fade>
        <Fade duration={500} delay={700} triggerOnce>
          {setLocations(secondSitesArr, 'North America')}
        </Fade>
      </div>
      <SplashText location={activeLocation} />
    </Layout>
  )
}

Index.propTypes = {
  sites: PropTypes.array,
  globalSettings: PropTypes.object,
  refreshOnChange: PropTypes.bool,
}
Index.defaultProps = {
  sites: [],
  globalSettings: {},
  refreshOnChange: false,
}

export default Index

export async function getStaticProps() {
  return {
    props: {},
    revalidate: 60 * 10,
  }
}
